// @flow

import * as React from 'react';
import classNames from 'classnames';

import AccessibilityIcon from '../AccessibilityIcon';

import Socials from '../Socials';
import * as css from './Header.scss';

type Props = {
	className?: string,
	isMobile: boolean,
};

const logo1Link = 'https://www.bankhapoalim.co.il/he';
const logo2Link = 'https://www.ynet.co.il/home/0,7340,L-8,00.html';
const logo3Link = 'https://www.yedioth.co.il/';

const Header = ({ className, isMobile }: Props) => {
	return (
		<div className={classNames(css.header, className)} id="header">
			<div className={css.headerWrapper}>
				<div className={css.centerLogos}>
					<a href={logo1Link} className={css.logo1} target="_blank" rel="noreferrer"></a>
					<a href={logo2Link} className={css.logo2} target="_blank" rel="noreferrer"></a>
					<a href={logo3Link} className={css.logo3} target="_blank" rel="noreferrer"></a>
				</div>
				<div className={css.leftSide}>
					<div className={css.sociallsWrapper}>
						<Socials minified={isMobile} />
						<AccessibilityIcon />
					</div>
				</div>
			</div>
		</div>
	);
};

Header.defaultProps = {
	className: '',
};

export { Header };
