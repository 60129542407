// @flow
import produce from 'immer';

import * as actions from 'client/constants/actions';

const initialState: SettingsClientStoreType = {
	isBusy: false,
	item: { id: '' },
};

type Action =
	| { type: typeof actions.GET_SETTINGS.START, payload: { collection: string } }
	| { type: typeof actions.GET_SETTINGS.SUCCESS, payload: GetClientSettingsResult<SettingsItemType> }
	| { type: typeof actions.GET_SETTINGS.FAIL, payload: { collection: string } };

export default function settingsReducer(state: SettingsClientStoreType = initialState, action: Action) {
	return produce<any>(state, (draft: SettingsClientStoreType) => {
		switch (action.type) {
			case actions.GET_SETTINGS.START:
				draft.isBusy = true;
				break;

			case actions.GET_SETTINGS.SUCCESS:
				draft.item = action.payload.item;
				draft.isBusy = false;
				break;

			case actions.GET_SETTINGS.FAIL:
				draft.isBusy = false;
				break;

			default:
				break;
		}
	});
}
