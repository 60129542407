/* eslint-disable react/button-has-type */
// @flow
import * as React from 'react';
import classNames from 'classnames';

// import { Text } from '../Text';
import * as css from './Button.scss';

type Props = {
	className?: string,
	label: string,
	color?: 'green' | 'blue',
	disabled?: boolean,
	styleType?: 'primary',
	type?: 'button' | 'submit' | 'reset',
};

class Button extends React.PureComponent<Props> {
	static defaultProps = {
		className: '',
		color: 'green',
		disabled: false,
		styleType: 'primary',
		type: 'button',
	};

	render() {
		const { className, label, color, styleType, type, ...rest } = this.props;

		return (
			<button {...rest} className={classNames(css.button, css[color], css[styleType], className)} type={type}>
				<p>{label}</p>
			</button>
		);
	}
}

export { Button };
