/* eslint-disable react/no-unescaped-entities */
/* eslint-disable max-len */
// @flow
/* eslint-disable react/no-danger */
import * as React from 'react';
import classNames from 'classnames';
import { type EnhancedProps, withTransition } from 'client/components/common/HOCs/with-transition';
import Main from 'client/components/common/Main';
import * as css from './Intro.scss';

type Props = {|
	...EnhancedProps,
	className?: string,
	in: boolean,
	history: RouterHistory,
	location: RouterLocation,
|};

type State = {};
class Intro extends React.PureComponent<Props, State> {
	transitionCompleteTimeout: TimeoutID;

	static defaultProps = {
		className: '',
	};

	state = {};

	componentDidMount() {}

	componentDidUpdate(prevProps: Props) {
		if (this.props.in !== prevProps.in) {
			const { onTransitionComplete } = this.props;
			let duration = parseInt(this.props.in ? css.transitionIn : css.transitionOut, 10);

			if (!this.props.in) {
				duration = 0;
			}
			clearTimeout(this.transitionCompleteTimeout);
			this.transitionCompleteTimeout = setTimeout(onTransitionComplete, duration);
		}
	}

	render() {
		const { className, transitionState, history, location } = this.props;
		// const isMobile = Responsive.isMatching(Responsive.MOBILE);

		return (
			<div className={classNames(css.intro, css[transitionState], className)}>
				<Main history={history} location={location} />
			</div>
		);
	}
}

export default withTransition(Intro);
