/* eslint-disable react/no-danger */
/* eslint-disable react/no-array-index-key */
// @flow
import React from 'react';
import css from './Main.scss';

type Props = { texts: Array<string> };

const Text = ({ texts }: Props) => {
	return (
		<div className={css.text}>
			{texts.map((text, i) => {
				return <p key={i} dangerouslySetInnerHTML={{ __html: text }}></p>;
			})}
		</div>
	);
};

export default Text;
