/* eslint-disable react/no-unescaped-entities */
/* eslint-disable no-unused-vars */
// @flow

import React, { useState, useRef, useEffect } from 'react';
import _ from 'lodash';
import classNames from 'classnames';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { EffectCoverflow, Navigation, Scrollbar } from 'swiper';

import { parseSearch } from 'common/utils/parse-react-router-search';
import { CAN_USE_DOM } from 'common/constants';
import { SOLDIERS_INFO } from 'client/constants';
import { Responsive } from 'client/components/common';

import topBgDesk from 'assets/client/images/desk_bg.png';
import topBgMob from 'assets/client/images/mobile_bg.png';
import Text from './Text';
import css from './Main.scss';

SwiperCore.use([EffectCoverflow, Navigation, Scrollbar]);

type Props = {| history: RouterHistory, location: RouterLocation |};

const Main = ({ history, location }: Props) => {
	const [swiper, setSwiper] = useState(null);
	const [activeIndex, setActiveIndex] = useState(0);
	const [soldierText, setSoldierText] = useState([]);
	const searchParams = parseSearch(location.search);
	const navigationPrevRef = useRef(null);
	const navigationNextRef = useRef(null);

	const isMobile = Responsive.isMatching(Responsive.MOBILE);
	const slidesPerView = isMobile ? 3 : 7;

	let initialSlide;

	if (!_.isEmpty(searchParams)) {
		const slideIndexQuery = +searchParams.s || 0;
		initialSlide = slideIndexQuery;
	} else {
		initialSlide = 0;
	}

	const renderSlide = (i: SoldierInfo, index: number) => {
		return (
			<div className={classNames(css.slide, activeIndex === index && css.active)}>
				<div className={css.soldierPic} style={{ backgroundImage: `url(${i.image})` }}></div>
				<div className={css.soldierName}>{i.name}</div>
				<div className={css.soldierNameBig}>{i.name}</div>
			</div>
		);
	};

	const onSlideChange = () => {
		if (swiper) {
			const index = swiper.activeIndex;
			setActiveIndex(swiper.realIndex);
		}
	};

	const handlePrev = () => {
		if (swiper) {
			swiper.slidePrev();
		}
	};

	const handleNext = () => {
		if (swiper) {
			swiper.slideNext();
		}
	};

	useEffect(() => {
		const soldierInfo = SOLDIERS_INFO.find(el => el.index === activeIndex + 1);
		if (soldierInfo) {
			const text = soldierInfo.texts;
			setSoldierText(text);
		}

		const urlSearchParams = new URLSearchParams(window.location.search);
		urlSearchParams.set('s', `${activeIndex}`);
		history.push({ pathname: `${location.pathname}`, search: `?${urlSearchParams.toString()}` });
	}, [activeIndex]);

	useEffect(() => {
		if (!_.isEmpty(searchParams)) {
			const slideIndexQuery = +searchParams.s || 0;
			const soldierInfo = SOLDIERS_INFO.find(el => el.index === slideIndexQuery);
			if (soldierInfo) {
				const text = soldierInfo.texts;
				setSoldierText(text);
			}

			setActiveIndex(slideIndexQuery);
		} else {
			const firstSoldierInfo = SOLDIERS_INFO.find(el => el.index === 1);

			if (firstSoldierInfo) {
				const text = firstSoldierInfo.texts;
				setSoldierText(text);
			}
		}
	}, []);

	return (
		<div className={css.mainWrapper}>
			<div className={css.topImage} style={{ backgroundImage: `url(${isMobile ? topBgMob : topBgDesk})` }}></div>
			<div className={css.desktopShadow}></div>
			<div className={css.contentWrapper}>
				<div className={css.title}>
					<h1>הקרב בחורשת משה ניר</h1>
					<h2>
						{' '}
						"ונתנה תוקף קדושת היום, כי הוא נורא ואיום". ביום הכיפורים שנת תשל"ד, בין מוסף למנחה, קרעה הצפירה
						את דומיית היום הקדוש.
					</h2>
				</div>
				<div className={css.soldiersSliderWrapper}>
					<Swiper
						grabCursor
						centeredSlides
						slidesPerView={slidesPerView}
						initialSlide={initialSlide}
						speed={1000}
						loop
						onSlideChange={onSlideChange}
						onSwiper={setSwiper}
						className={css.soldiersSlider}
						slideToClickedSlide
					>
						{_.map(SOLDIERS_INFO, (i, slideIndex) => {
							return (
								<SwiperSlide className={css.slideWrapper} key={i.index}>
									{renderSlide(i, slideIndex)}
								</SwiperSlide>
							);
						})}
					</Swiper>
					<div className={css.btns}>
						<div className={classNames(css.btn, css.prevArrow)} onClick={handlePrev} />
						<div className={classNames(css.btn, css.nextArrow)} onClick={handleNext} />
					</div>
					<div className={css.shadowLeft}></div>
					<div className={css.shadowRight}></div>
				</div>
				<Text texts={soldierText} />
			</div>
		</div>
	);
};

export default Main;
