// @flow

/* eslint-disable max-len,react/jsx-tag-spacing */
import * as React from 'react';
import classNames from 'classnames';
import { Responsive } from '../Responsive';
import * as css from './Icon.scss';

type Props = {|
	type: string,
	className?: string,
	width?: number,
	height?: number,
	color?: string,
|};

/**
 * @link - https://material.io/tools/icons/?style=baseline
 * @return {XML}
 */
const Icon = ({ type, className, width, height, color, ...iconProps }: Props) => {
	let icon = null;
	const svgProps = {
		width,
		height,
		viewBox: `0 0 ${width || 24} ${height || 24}`,
		xmlns: 'http://www.w3.org/2000/svg',
	};
	const isDesktop = Responsive.isMatching(Responsive.DESKTOP);

	switch (type) {
		case 'plus':
			icon = (
				<svg {...svgProps}>
					<g id="Group_1244" data-name="Group 1244" transform="translate(-234.5 -597.5)">
						<rect
							id="Rectangle_391"
							data-name="Rectangle 391"
							className="cls-1"
							width="4"
							height="24"
							transform="translate(244.5 597.5)"
							fill="#468aff"
						/>
						<rect
							id="Rectangle_392"
							data-name="Rectangle 392"
							className="cls-1"
							width="24"
							height="4"
							transform="translate(234.5 607.5)"
							fill="#468aff"
						/>
					</g>
				</svg>
			);
			break;
		case 'whatsapp':
			icon = (
				<svg {...svgProps}>
					<path
						fill="#e20a17"
						d="M14249 6987a16 16 0 1 1 16 16 16 16 0 0 1-16-16zm6.763-.266a9.56 9.56 0 0 0 1.28 4.789l-1.358 4.96 5.074-1.333a9.6 9.6 0 0 0 4.577 1.168 9.577 9.577 0 1 0-9.577-9.584zm9.573 7.964a7.949 7.949 0 0 1-4.05-1.107l-.29-.172-3.015.789.807-2.936-.19-.3a7.96 7.96 0 1 1 6.742 3.728zm-4.024-12.082a2.674 2.674 0 0 0-.835 1.993 4.678 4.678 0 0 0 .975 2.477 10.7 10.7 0 0 0 4.089 3.609 13.637 13.637 0 0 0 1.362.505 3.233 3.233 0 0 0 1.509.093 2.452 2.452 0 0 0 1.613-1.136 1.981 1.981 0 0 0 .14-1.136c-.062-.1-.219-.161-.459-.279s-1.416-.7-1.635-.778-.38-.122-.537.118-.62.778-.756.939-.28.179-.521.058a6.489 6.489 0 0 1-1.924-1.187 7.162 7.162 0 0 1-1.334-1.656c-.136-.24 0-.358.107-.487a6.871 6.871 0 0 0 .6-.821.439.439 0 0 0-.021-.419c-.057-.118-.537-1.294-.738-1.774-.172-.412-.348-.412-.487-.412h-.05c-.14-.007-.3-.007-.459-.007a.88.88 0 0 0-.64.3z"
						transform="translate(-14248.999 -6971)"
					/>
				</svg>
			);
			break;
		case 'facebook':
			icon = (
				<svg {...svgProps}>
					<path
						fill="#e20a17"
						d="M16 .349a15.991 15.991 0 0 0-2.668 31.76V19.687h-3.86v-4.47h3.86v-3.3c0-3.824 2.336-5.908 5.748-5.908a31.853 31.853 0 0 1 3.446.175v4h-2.367c-1.855 0-2.213.881-2.213 2.175v2.853h4.427l-.577 4.47h-3.85v12.534A15.992 15.992 0 0 0 16 .349z"
						transform="translate(0 -0.349)"
					/>
				</svg>
			);
			break;
		case 'twitter':
			icon = (
				<svg xmlns="http://www.w3.org/2000/svg" width="24.5" height="24.5" viewBox="0 0 24.5 24.5">
					<g id="twitter_share" data-name="twitter share" transform="translate(0)">
						<path
							id="Path_497"
							data-name="Path 497"
							d="M2733.72,2444.775c-.275,16.335-24.227,16.332-24.5,0C2709.495,2428.442,2733.447,2428.444,2733.72,2444.775Z"
							transform="translate(-2709.22 -2432.526)"
							fill="#e20a17"
						/>
						<path
							id="Path_498"
							data-name="Path 498"
							d="M2930.952,2692.917c5.592,3.853,12.971-1.368,12.355-7.234a5.778,5.778,0,0,0,1.409-1.483,5.589,5.589,0,0,1-1.622.451,2.861,2.861,0,0,0,1.241-1.585,5.609,5.609,0,0,1-1.792.7,2.844,2.844,0,0,0-4.812,2.613,7.981,7.981,0,0,1-5.82-2.994,2.894,2.894,0,0,0,.874,3.825,2.77,2.77,0,0,1-1.28-.358,2.859,2.859,0,0,0,2.266,2.845,2.786,2.786,0,0,1-1.275.049,2.829,2.829,0,0,0,2.637,1.989A5.614,5.614,0,0,1,2930.952,2692.917Z"
							transform="translate(-2924.669 -2675.671)"
							fill="#fff"
						/>
					</g>
				</svg>
			);
			break;
		case 'share':
			icon = (
				<svg {...svgProps}>
					<g>
						<rect data-name="Rectangle 299" width={width} height={height} rx="17.5" fill={color} />
						<g data-name="Group 366">
							<g data-name="Group 365">
								<g data-name="Group 364">
									<path
										data-name="Path 1246"
										d="M5.8 6.858a2.977 2.977 0 0 1 0 1.667l5.471 2.9a2.358 2.358 0 1 1-.554 1.046l-5.471-2.9a2.958 2.958 0 1 1 0-3.758l5.471-2.9a2.368 2.368 0 1 1 .554 1.046z"
										fill="#fff"
										transform="translate(8.875 10.057)"
									/>
								</g>
							</g>
						</g>
					</g>
				</svg>
			);
			break;
		case 'accessibility':
			icon = (
				<svg {...svgProps}>
					<g id="ng" transform="translate(-18 -24)">
						<rect
							id="Rectangle_299"
							data-name="Rectangle 299"
							width={width}
							height={height}
							rx={width ? width / 2 : '17'}
							transform="translate(18 24)"
							fill="#515153"
						/>
						<g
							id="XMLID_28_"
							transform={`${isDesktop ? 'translate(-19.379 32)' : 'translate(-15.379 36)'}`}
						>
							<path
								id="XMLID_31_"
								fill="#fff"
								d="M178.532 3.418a1.709 1.709 0 1 0-1.709-1.709 1.709 1.709 0 0 0 1.709 1.709z"
								transform="translate(-124.881)"
							/>
							<path
								id="XMLID_30_"
								fill="#fff"
								d="M56.292 204.681a3.777 3.777 0 1 1-5.149-4.5v-1.6a5.281 5.281 0 1 0 6.313 7.269l-.6-1.172z"
								transform="translate(0 -191.581)"
							/>
							<path
								id="XMLID_29_"
								fill="#fff"
								d="m191.737 128.291-2.289-4.5a1 1 0 0 0-.894-.549h-2.931v-.668h2.523a.789.789 0 0 0 .753-.753.752.752 0 0 0-.753-.753h-2.523V118.8a1.5 1.5 0 1 0-3.01 0v4.912a1.535 1.535 0 0 0 1.535 1.535h3.791l2.01 3.952a1 1 0 1 0 1.788-.91z"
								transform="translate(-130.467 -113.162)"
							/>
						</g>
					</g>
				</svg>
			);
			break;
		default:
			break;
	}

	return (
		<i className={classNames(css.icon, className)} {...iconProps}>
			{icon}
		</i>
	);
};

Icon.defaultProps = {
	className: '',
	width: 24,
	height: 24,
	color: '#111',
};

export { Icon };
